import React, { useState } from "react";
import { useFormik, FormikProvider } from "formik";
import * as yup from "yup";
import toast from "react-hot-toast";
import { Form, Button, Card, Row, Col } from "react-bootstrap";
import { Redirect } from "react-router-dom";

import { useLoginUser } from "../../store/hooks/userHooks";
import { User, UserLogin } from "../../shared/@types/user";
// import Logo from "../../shared/img/adpase.png";

import styles from "./styles.module.scss";

const loginSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required(),
});

const Login = () => {
  const loginUser = useLoginUser();
  const [redirect, setRedirect] = useState(false);

  const handleSubmit = (values: User) => {
    const result: any = loginUser(values);

    toast.promise(result, {
      loading: "Validando informações...",
      success: (res: UserLogin) => {
        if (res && res.access_token) {
          setRedirect(true);
        }
        return "Acessando sistema!";
      },
      error: (error) => {
        return error.response.data.error;
      },
    });
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginSchema,
    onSubmit: (values: Partial<User>) => {
      handleSubmit(values);
    },
  });

  const { handleChange, values, errors, touched } = formik;

  if (redirect) {
    return <Redirect to="/home" />;
  }

  return (
    <div className={styles.login}>
      <Row className={styles.containerLogin}>
        {/* <Col xs={6} sm={6} md={6} lg={4}>
          <img src={Logo} alt="logo" style={{ width: "350px" }} />
        </Col> */}
        <Col md={6} lg={4}>
          <Card style={{ width: "18rem" }}>
            <Card.Body>
              <Card.Title>Sistema de gestão Flips</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">
                Adpase
              </Card.Subtitle>
              <Card.Text>
                {/* Aqui você irá encontrar o gerenciamento das atividades. */}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} lg={4}>
          <Card>
            <Card.Body>
              <FormikProvider value={formik}>
                <Form onSubmit={formik.handleSubmit}>
                  <Col md={12}>
                    <h2>Login</h2>
                    <Form.Group>
                      <Form.Label>E-mail</Form.Label>
                      <Form.Control
                        id="email"
                        name="email"
                        type="email"
                        onChange={handleChange}
                        value={values.email}
                        data-testid="login-email"
                      />
                      {touched.email && errors.email && (
                        <span className="error">Insira um e-mail válido</span>
                      )}
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Senha</Form.Label>
                      <Form.Control
                        id="password"
                        name="password"
                        type="password"
                        onChange={handleChange}
                        value={values.password}
                        data-testid="login-password"
                      />
                      {touched.password && errors.password && (
                        <span className="error">Insira uma senha válida</span>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={12} className="button-save">
                    <Button variant="primary" type="submit">
                      Enviar
                    </Button>
                  </Col>
                </Form>
              </FormikProvider>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Login;
